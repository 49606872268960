import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const Outer = styled.div`
  display: none;
  @media (max-width: 850px) {
    display: ${(props) => (props.open ? "flex" : "none")};
    background-color: #f3f3ed;
    transition: all 0.25s ease-in-out;
    z-index: 10;
    position: absolute;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 20px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 43px;
    margin-top: 43px;
    a {
      text-decoration: none;
      color: #000;
      font-family: "Stileman";
      font-size: 16px;
      text-transform: uppercase;
    }
  }
`;

const Overlay = ({ open }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanitySettings {
        edges {
          node {
            hide_finishes
            _id
          }
        }
      }
    }
  `);

  const hide_finishes = data.allSanitySettings.edges.find(
    (x) => x.node._id === "settings",
  )?.node?.hide_finishes;

  return (
    <Outer open={open}>
      <ul>
        <li>
          <a href="/products">Products</a>
        </li>
        <li>
          <a href="/about">About</a>
        </li>
        {hide_finishes !== true && (
          <li>
            <a href="/finishes">Finishes</a>
          </li>
        )}
      </ul>
    </Outer>
  );
};

export default Overlay;
