import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";
import CloseBlack from "../../images/close_black.svg";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
  left: 40px;
  right: 40px;
  position: absolute;
  z-index: 100;
  span.no-link {
    padding-top: 33px;
    padding-bottom: 33px;
    @media (max-width: 900px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &.home {
    opacity: 0;
    animation: ${fadeIn} 1s ease-in-out 1s 1 forwards;
  }
  @media (max-width: 900px) {
    left: 20px;
    right: 20px;
  }
  @media (max-width: 450px) {
    right: 40px;
  }
  ul {
    list-style: none;
    padding-left: 0;
    @media (max-width: 850px) {
      display: none;
    }
  }
  li {
    display: inline-block;
    margin-left: 50px;
    @media (max-width: 700px) {
      display: none;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  span,
  li {
    font-family: "Stileman";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19.6px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    @media (max-width: 549px) {
      font-size: 16px;
    }
    @media (min-width: 2000px) {
      font-size: 18px;
      line-height: 25.2px;
    }
    span {
      border-bottom: 1px solid transparent;
      transition: all 0.15s linear;
    }
    a {
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      pointer-events: auto;
      padding-top: 33px;
      padding-bottom: 33px;
      display: inline-block;
      border-bottom: 1px solid transparent;
      color: #000;
      @media (max-width: 900px) {
        padding-top: 25px;
        padding-bottom: 25px;
        border-bottom: none !important;
        span {
          border-bottom: none !important;
        }
      }
      &:hover {
        span {
          border-bottom: 1px solid #000;
          &.home {
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }
`;

const Burger = styled.div`
  display: none;
  @media (max-width: 850px) {
    pointer-events: auto;
    display: block;
    z-index: 10000000;
    position: absolute;
    top: 14px;
    padding: 16px;
    right: 0px;
    @media (max-width: 450px) {
      right: -32px;
    }
  }
`;

const Nav = ({ path, open, toggleOpen, cn, visited }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanitySettings {
        edges {
          node {
            hide_finishes
            _id
          }
        }
      }
    }
  `);

  const hide_finishes = data.allSanitySettings.edges.find(
    (x) => x.node._id === "settings",
  )?.node?.hide_finishes;

  const handleClick = (e) => {
    toggleOpen(!open);
  };

  return (
    <>
      <Container className={cn}>
        {path === "/" && <span className="no-link">Stileman Collection</span>}
        {path !== "/" && (
          <span>
            <Link to="/">
              <span className={path === "/" ? "home" : "not-home"}>
                Stileman Collection
              </span>
            </Link>
          </span>
        )}
        <ul>
          {path.includes("/products") && (
            <li>
              <span className="no-link">Products</span>
            </li>
          )}

          {!path.includes("/products") && (
            <li>
              <Link to="/products">
                <span>Products</span>
              </Link>
            </li>
          )}

          {path.includes("/about") && (
            <li>
              <span className="no-link">About</span>
            </li>
          )}
          {!path.includes("/about") && (
            <li>
              <Link to="/about">
                <span>About</span>
              </Link>
            </li>
          )}

          {hide_finishes !== true && path.includes("/finishes") && (
            <li className="finishes">
              <span className="no-link">Finishes</span>
            </li>
          )}
          {hide_finishes !== true && !path.includes("/finishes") && (
            <li className="finishes">
              <Link to="/finishes">
                <span>Finishes</span>
              </Link>
            </li>
          )}
        </ul>
        <Burger className="burger-time">
          {open && (
            <img
              onClick={(e) => {
                handleClick(e);
              }}
              src={CloseBlack}
              alt="menu"
            />
          )}
          {!open && (
            <svg
              onClick={(e) => {
                handleClick(e);
              }}
              width="19"
              height="11"
              viewBox="0 0 19 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="19"
                y1="0.5"
                x2="4.37114e-08"
                y2="0.500002"
                stroke="currentColor"
              />
              <line
                x1="19"
                y1="5.5"
                x2="4.37114e-08"
                y2="5.5"
                stroke="currentColor"
              />
              <line
                x1="19"
                y1="10.5"
                x2="4.37114e-08"
                y2="10.5"
                stroke="currentColor"
              />
            </svg>
          )}
        </Burger>
      </Container>
    </>
  );
};

export default Nav;
