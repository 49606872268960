import React, { useState } from "react";
import Nav from "../components/nav";
import Overlay from '../components/overlay'
import Intro from '../components/intro'
import useLocalStorage from "../hooks/useLocalStorage";

import { motion, AnimatePresence } from "framer-motion";

import { createGlobalStyle } from "styled-components";

import Stileman_woff from "../../static/fonts/StilemanSans4.0-Regular.woff";
import Stileman_woff2 from "../../static/fonts/StilemanSans4.0-Regular.woff2";

import MessinaSans_Book_woff from "../../static/fonts/MessinaSansWeb-Book.woff";
import MessinaSans_Book_woff2 from "../../static/fonts/MessinaSansWeb-Book.woff2";

import MessinaSans_Italic_woff from "../../static/fonts/MessinaSansWeb-BookItalic.woff";
import MessinaSans_Italic_woff2 from "../../static/fonts/MessinaSansWeb-BookItalic.woff2";

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  [style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }

  [style*="--aspect-ratio"] > img {
    height: auto;
  }

  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
  }

  @supports not (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      height: 0;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
  }

  @supports (aspect-ratio: 1/1) {
    [style*="--aspect-ratio"]::before {
      aspect-ratio: calc(var(--aspect-ratio));
   }
  }

  a:focus {
    outline: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  html,
  body {
    height: 100%;
    background-color: #F3F3ED;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    &.fp-viewing-0 {
      div.tophalf {
        cursor: auto;
      }
    }
    &.dark {
      nav a {
        color: #000;
      }
      nav a:hover span {
        border-color: #000;
      }
    }
    &.light {
      nav a {
        color: #000;
      }
      nav a:hover span {
        border-color: #000;
      }
    }
  }

  body.noscroll {
    overflow-y: hidden;
    @media (max-width: 550px) {
      overflow-y: auto;
    }
  }

  .burger-time svg {
    transition: all 0.15s linear;
  }

  body.light .burger-time svg {
    color: #000;
    fill: #000;
  }

  body.dark .burger-time svg {
    color: #fff;
    fill: #fff;
  }

  .fp-watermark {
    opacity: 0;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  #root,
  #__next {
    isolation: isolate;
  }

  @font-face {
    font-family: "Stileman";
    src: url(${Stileman_woff2}) format("woff2"),
      url(${Stileman_woff}) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "MessinaSans";
    src: url(${MessinaSans_Book_woff2}) format("woff2"),
      url(${MessinaSans_Book_woff}) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "MessinaSans";
    src: url(${MessinaSans_Italic_woff2}) format("woff2"),
      url(${MessinaSans_Italic_woff}) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  main {
    padding-top: 70px;
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: "MessinaSans", Arial, Helvetica, sans-serif;
    a {
      transition: all 0.25s ease-in-out;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      &:hover {
        border-bottom: 0.5px solid #00000088;
      }
    }

  }

`;
export default function Layout({ children, path }) {

  const [open, toggleOpen] = useState(false);

  const [visited, setVisited] = useLocalStorage("visited", false);
  const cn = (path === '/' && visited === false) ? 'home' : 'not-home'


  return (
    <React.Fragment>
      <GlobalStyle />
      <Intro />
      <Overlay open={open} />
      <Nav cn={cn} visited={visited} path={path} open={open} toggleOpen={toggleOpen} />
      <motion.div
        key={path}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0, duration: 0.5 }}>
        {children}
      </motion.div>
    </React.Fragment>
  );
}
