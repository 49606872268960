import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Logo from '../../images/stileman_collection.svg'

import useSessionStorage from "../../hooks/useSessionStorage";

const fadeOutContainer = keyframes`
  0%   { opacity:1; }
  50%   { opacity:1; }
  100% { opacity:0; }
`

const fadeInImage = keyframes`
  0%   { opacity:0; }
  100% { opacity:1; }
`

const Container = styled.div`
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeOutContainer} 1.5s ease-in-out 2s 1 forwards;
  pointer-events: none;
  background-color: #F3F3ED;
  z-index: 99999999999999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer !important;
  img {
    opacity: 0;
    animation: ${fadeInImage} 0.8s ease-in-out 0s 1 forwards;
  }
  @media(max-width: 850px) {
    img {
      max-width: 90%;
    }
  }
`

const Intro = () => {

  const [seen, setSeen] = useSessionStorage("viewed", false);

  useEffect(() => {
    if (!seen) {
      const id = setTimeout(() => {
        setSeen("true");
      }, 4000);
      return () => clearTimeout(id);
    }
  }, []);

  if(seen) {
    return (<></>)
  } else {
    return (
      <Container>
        <img style={{ opacity: 0 }} src={Logo} alt="Stileman Collection" />
      </Container>
    )

  }

}

export default Intro
