import React from 'react'
import { AnimatePresence } from 'framer-motion'

export function wrapPageElement({ element, props }) {
  return <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>;
}

export function shouldUpdateScroll ({routerProps: { location }, getSavedScrollPosition}) {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  if(window !== undefined) {
    console.log('scrolllling')
    window.scrollTo(0, 0)
  }

  return false
}
